<script>
import {defineComponent} from 'vue';

import {
  required,
  email,
  helpers
} from "@vuelidate/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";

import {LOGIN_USER} from "@/graphql/mutations/auth/login";
import {AUTH_USER} from "@/graphql/queries/auth/auth";
import { useRouter } from 'vue-router';
import {useVuelidate} from "@vuelidate/core";

export default defineComponent({
  setup () {
    const router = useRouter();
    const v$ = useVuelidate()

    return {router,v$};
  },
  page: {
    title: "account",
    meta: [{
      name: "description",
      content: "iTOCAPP",
    },],
  },
  data() {
    return {
      email: "geovanny@iliux.com",
      password: "Secret123456#",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      btnDisabled: false,
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("El correo electrónico es requerido", required),
      email: helpers.withMessage("Ingresa un correo electrónico valido", email),
    },
    password: {
      required: helpers.withMessage("La contraseña es requerida", required),
    },
  },
  computed: {},
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    async signinapi() {
      try {
        this.submitted = true;
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const {data} = await this.$apollo.mutate({
          mutation: LOGIN_USER,
          variables: {
            input: {
              email: this.email,
              password: this.password,
            }
          }
        });

        localStorage.setItem('accessToken', data.login.accessToken);

        await this.tryToLogIn();
        this.redirectTo();
      } catch (error) {
        return this.authError = error.message;
      }
    },

    redirectTo() {
      const $vm = this;

      setTimeout(async () => {
        window.location.reload();
        $vm.router.push({
          path: '/groups'
        });
      }, 1500)
    },

    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      const accessToken = localStorage.accessToken

      try {
        const {data} = await this.$apollo.query({
          query: AUTH_USER,
          context: {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        });

        sessionStorage.setItem("authUser", JSON.stringify(data.auth));
        this.setAuthUser(data.auth);
      } catch (error) {
        return this.authError = error.message;
      }
    },
  },
})


</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">

      <div class="shape"></div>

      <div class="bg-overlay"></div>

    </div>

    <div class="auth-page-content">
      <b-container>
        <b-row>
          <b-col lg="12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/login/itoc_adixnt_header.png" alt="" height="120"/>
                </router-link>
              </div>

            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-center">
          <b-col md="8" lg="6" xl="5">
            <b-card no-body class="mt-4">
              <b-card-body class="p-4">
                <div class="text-center mt-2">
                  <h5 class="text-danger text-uppercase">Bienvenido</h5>
                </div>
                <div class="p-2 mt-4">
                  <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                  <div>

                  </div>

                  <form @submit.prevent="signinapi">
                    <div class="mb-3">
                      <label for="email" class="form-label text-white">Correo Electrónico</label>
                      <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Ingresa tu correo electrónico"
                          v-model="email"
                          :class="{
                            'is-invalid': submitted && v$.email.$error,
                          }"
                      />

                      <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/forgot-password" class="text-white">¿Olvidaste tu contraseña?</router-link>
                      </div>
                      <label for="password-input" class="form-label text-white">Contraseña</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                            type="password"
                            class="form-control pe-5"
                            placeholder="Ingresa tu contraseña"
                            id="password-input"
                            v-model="password"
                            :class="{
                              'is-invalid': submitted && v$.password.$error,
                            }"
                        />

                        <b-button
                            variant="link"
                            class="position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                        >
                          <i class="ri-eye-fill align-middle"></i>
                        </b-button>

                        <div v-for="(item, index) in v$.password.$errors" :key="index" class="invalid-feedback">
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>

                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check"/>
                      <label for="auth-remember-check" class="form-check-label text-white">Recordarme</label>
                    </div>

                    <div class="mt-4 text-uppercase">
                      <b-button variant="danger" class="w-100" type="submit">
                        Iniciar sesión
                      </b-button>
                    </div>

                  </form>
                </div>
              </b-card-body>
            </b-card>

          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

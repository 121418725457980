import gql from 'graphql-tag'

export const LOGIN_USER = gql`
    mutation login ($input: LoginInput!){
        login(input: $input){
            tokenType
            accessToken
        }
    }
`

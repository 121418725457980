import gql from 'graphql-tag'

export const AUTH_USER = gql`
    query {
        auth {
            id
            name
            lastName
            email
            profile
            account {
                id
                name
            }
        }
    }
`
